import React from 'react';
import styled from 'styled-components';
import styling from '../../styling';
import { MDXRenderer } from 'gatsby-plugin-mdx';

const StyledPanel = styled.section`
  padding: 1em;
  border: 1px solid ${styling.colors.primary.normal};
  border-radius: ${styling.sizes.borderRadius};
`;

const StyledProjectOverview = styled(StyledPanel)`
  background-color: ${styling.colors.primary.L95};

  :not(:last-child) {
    margin-bottom: 2em;
  }

  a {
    > h2 {
      color: ${styling.colors.link.headline};
    }
  }

  h2 {
    margin: 0;
    font-size: 1.25em;
  }

  p {
    margin-top: 0;
  }
`;

interface Props {
  project: Project;
}

const ProjectOverview = ({ project }: Props) => (
  <StyledProjectOverview>
    {project.frontmatter.link != null ? (
      <a href={project.frontmatter.link} target="_blank">
        <h2>{project.frontmatter.name}</h2>
      </a>
    ) : (
      <h2>{project.frontmatter.name}</h2>
    )}
    <MDXRenderer>{project.body}</MDXRenderer>
  </StyledProjectOverview>
);

export default ProjectOverview;
