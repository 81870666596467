import { graphql } from 'gatsby';
import ProjectsPage from '../components/projects';

export default ProjectsPage;

export const query = graphql`
  {
    active: allMdx(
      sort: { fields: [frontmatter___priority], order: DESC }
      filter: { fileAbsolutePath: { regex: "/projects/descriptions/" }, frontmatter: { state: { eq: "Active" } } }
    ) {
      edges {
        node {
          frontmatter {
            name
            link
            state
          }
          body
        }
      }
    }
    inProgress: allMdx(
      sort: { fields: [frontmatter___priority], order: DESC }
      filter: { fileAbsolutePath: { regex: "/projects/descriptions/" }, frontmatter: { state: { eq: "InProgress" } } }
    ) {
      edges {
        node {
          frontmatter {
            name
            link
            state
          }
          body
        }
      }
    }
    abandoned: allMdx(
      sort: { fields: [frontmatter___priority], order: DESC }
      filter: { fileAbsolutePath: { regex: "/projects/descriptions/" }, frontmatter: { state: { eq: "Abandoned" } } }
    ) {
      edges {
        node {
          frontmatter {
            name
            link
            state
          }
          body
        }
      }
    }
    discontinued: allMdx(
      sort: { fields: [frontmatter___priority], order: DESC }
      filter: { fileAbsolutePath: { regex: "/projects/descriptions/" }, frontmatter: { state: { eq: "Discontinued" } } }
    ) {
      edges {
        node {
          frontmatter {
            name
            link
            state
          }
          body
        }
      }
    }
  }
`;
