import React from 'react';
import Layout from '../layout';
import ProjectOverview from './projectOverview';
import styled from 'styled-components';
import styling from '../../styling';
import { Page } from '../layout/header';

const StyledMain = styled.main`
  padding: 2em 0;
`;

// only render for screen readers
const StyledH1 = styled.h1`
  margin: 0;
  height: 0;
  overflow: hidden;
`;

interface Props {
  location: Location;
  data: {
    active: {
      edges: {
        node: Project;
      }[];
    };
    inProgress: {
      edges: {
        node: Project;
      }[];
    };
    abandoned: {
      edges: {
        node: Project;
      }[];
    };
    discontinued: {
      edges: {
        node: Project;
      }[];
    };
  };
}

const StyledH2 = styled.h2`
  :not(:first-of-type) {
    margin-top: 5rem;
  }
`;

const ProjectsPage = (props: Props) => (
  <Layout title="Projects" currentPage={Page.Projects} location={props.location}>
    <StyledMain>
      <StyledH1>Projects</StyledH1>
      <StyledH2>In Progress</StyledH2>
      {props.data != null && props.data.inProgress.edges.map((edge) => <ProjectOverview project={edge.node} />)}
      <StyledH2>Active</StyledH2>
      {props.data != null && props.data.active.edges.map((edge) => <ProjectOverview project={edge.node} />)}
      <StyledH2>Discontinued</StyledH2>
      {props.data != null && props.data.discontinued.edges.map((edge) => <ProjectOverview project={edge.node} />)}
      <StyledH2>Abandoned</StyledH2>
      {props.data != null && props.data.abandoned.edges.map((edge) => <ProjectOverview project={edge.node} />)}
    </StyledMain>
  </Layout>
);

export default ProjectsPage;
